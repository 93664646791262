import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { fireGAEvent } from '../../shared/trackingUtils';

const triggerGAEvent = (label, value) => {
	fireGAEvent({
		eventCategory: 'Template Area Interaction',
		eventAction: 'click',
		eventLabel: label,
		eventValue: value,
	});
};

const FourCardArticle = ({
	isReversed,
	data,
	themeColor,
	CardArticleComponent,
	CardStreamComponent,
}) => {
	const [largeCard, ...smallCards] = data;

	return (
		<div className={cx('four-card-article', { 'is-reversed': !!isReversed })}>
			<CardArticleComponent
				themeColor={themeColor}
				inViewTrackingProps={largeCard.index === 0 ? {
					category: 'Template Area Interaction',
					action: 'in-view',
					label: 'xl-recirc',
				} : undefined}
				inView={false}
				image={largeCard.image}
				href={largeCard.uri}
				alt={largeCard.alt}
				size={largeCard.size}
				title={largeCard.title}
				isPremium={largeCard.isPremium}
				className="card-article"
				classNameByLines="custom-stream"
				onClick={() => triggerGAEvent(`More From Forbes - XL Recirc - Story ${largeCard.index + 1}`, largeCard.index + 1)}
				isLazyLoading
				authors={[
					{
						link: largeCard.authors[0]?.link,
						name: largeCard.authors[0]?.name,
						type: largeCard.authors[0]?.type,
					},
				]}
			/>
			<div className="card-stream-container">
				{smallCards.map(({
					title, uri, authors, index, image,
				}) => (
					<CardStreamComponent
						className="card-stream"
						key={`${title}-${index}`}
						articleUrl={uri}
						title={title}
						target="_self"
						description=""
						onClick={() => triggerGAEvent(`More From Forbes - XL Recirc - Story ${index + 1}`, index + 1)}
						loading="lazy"
						imageSrc={image.replace('width=500', 'width=160')}
						imageAlignment={isReversed ? 'left' : 'right'}
						byLineProps={{
							author: [
								{
									link: authors[0]?.link,
									name: authors[0]?.name,
									type: authors[0]?.type,
								},
							],
							displayBy: true,
							size: 'small',
							className: 'custom-stream',
						}}
					/>
				))}
			</div>
		</div>
	);
};

FourCardArticle.propTypes = {
	isReversed: PropTypes.bool,
	data: PropTypes.array.isRequired,
	themeColor: PropTypes.string,
	CardArticleComponent: PropTypes.elementType.isRequired,
	CardStreamComponent: PropTypes.elementType.isRequired,
};

export default FourCardArticle;
