/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import CardGridArticle from '../CardGridArticle/CardGridArticle';
import cardArticleService from './cardArticleService';
import useWindowWidth from '../../../../next/hooks/useWindowWidth';
import createGroupedArticleData from './createGroupedArticleData';

const InlineAdX = ({
	addProgressiveAd,
	adArticleIndex,
	streamIndex,
	AdContainerComponent,
}) => (
	<AdContainerComponent
		type="inlineMobile"
		className={cx('inline-ad-container', 'xl-recirc-inline-ad')}
		showLabel
	>
		<fbs-ad
			progressive="true"
			position="mob-stream"
			id={`article-0-ad-${adArticleIndex}`}
			ad-id={`inline-article-0-ad-${adArticleIndex}`}
			class="article-mobile-ad"
			ref={addProgressiveAd}
			custom-targeting={`mobilex:stream${streamIndex},mffxlrecirc:true`}
		/>
	</AdContainerComponent>
);

InlineAdX.propTypes = {
	addProgressiveAd: PropTypes.func,
	adArticleIndex: PropTypes.number,
	streamIndex: PropTypes.number,
	AdContainerComponent: PropTypes.elementType.isRequired,
};

const MobileXLRecirc = ({
	articles,
	themeColor,
	showAds,
	addProgressiveAd,
	mobileAdsSelector,
	CardArticleComponent,
	AdContainerComponent,
}) => {
	const chunkSizeA = 4;
	const chunkSizeB = 5; // 1 large card, 4 grid cards
	const adArticleIndex = useRef(undefined);
	const streamIndex = useRef(0);
	const forceSquareAspectRatio = useWindowWidth() <= 768;

	useEffect(() => {
		if (showAds) {
			const mobileXArray = document.querySelectorAll(mobileAdsSelector);
			adArticleIndex.current = mobileXArray.length;
		}
	}, [mobileAdsSelector, showAds]);

	const groupedData = useMemo(() => createGroupedArticleData(
		articles,
		[chunkSizeA, chunkSizeB],
		['CardArticleComponent', 'CardGridArticle'],
	), [articles, chunkSizeA, chunkSizeB]);

	return (
		groupedData.map(({ type, data }, index) => (
			<>
				<div className="card-article-wrapper">
					{type === 'CardArticleComponent' && data.map(cardArticleService({ CardArticleComponent, themeColor, length: data.length }))}
				</div>

				{type === 'CardGridArticle' && (
					<>
						{cardArticleService({
							CardArticleComponent, themeColor, className: 'single-card', forceSquareAspectRatio,
						})(data[0], 0)}

						<CardGridArticle
							CardArticleComponent={CardArticleComponent}
							data={data.slice(1)}
							themeColor={themeColor}
							key={index + 1}
						/>
					</>
				)}

				{showAds && index < groupedData.length - 1 && (
					<InlineAdX
						addProgressiveAd={addProgressiveAd}
						adArticleIndex={adArticleIndex.current + index}
						streamIndex={streamIndex.current + 1 + index}
						AdContainerComponent={AdContainerComponent}
					/>
				)}
			</>
		))
	);
};

MobileXLRecirc.propTypes = {
	articles: PropTypes.array,
	themeColor: PropTypes.string,
	showAds: PropTypes.bool,
	addProgressiveAd: PropTypes.func,
	mobileAdsSelector: PropTypes.string,
	CardArticleComponent: PropTypes.elementType.isRequired,
	AdContainerComponent: PropTypes.elementType.isRequired,
};

export default MobileXLRecirc;
