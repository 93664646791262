/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import CardGridArticle from '../CardGridArticle/CardGridArticle';
import createGroupedArticleData from './createGroupedArticleData';

const DesktopXLRecirc = ({
	articles,
	themeColor,
	isVetted,
	showAds,
	addProgressiveAd,
	CardArticleComponent,
	CardStreamComponent,
	FourCardArticleComponent,
	AdContainerComponent,
}) => {
	const chunkSizeA = isVetted ? 6 : 4;
	const chunkSizeB = 4;
	let isReversed = false;
	let streamIndex = 0;

	const groupedData = useMemo(() => createGroupedArticleData(
		articles,
		[chunkSizeA, chunkSizeB],
		['CardGridArticle', ['FourCardARticle'],
		]), [articles, chunkSizeA, chunkSizeB]);

	return (
		groupedData.map(({ type, data }, index) => {
			if (index % 2 === 0) { isReversed = !isReversed; }

			if (type === 'CardGridArticle') {
				streamIndex += 1;

				return (
					<CardGridArticle
						CardArticleComponent={CardArticleComponent}
						AdContainerComponent={AdContainerComponent}
						data={data}
						themeColor={themeColor}
						isReversed={!isReversed}
						index={streamIndex}
						key={index + 1}
						showAds={showAds}
						addProgressiveAd={addProgressiveAd}
					/>
				);
			}

			return (
				<FourCardArticleComponent
					CardArticleComponent={CardArticleComponent}
					CardStreamComponent={CardStreamComponent}
					themeColor={themeColor}
					isReversed={!isReversed}
					key={index + 1}
					data={data}
				/>
			);
		})
	);
};

DesktopXLRecirc.propTypes = {
	articles: PropTypes.array.isRequired,
	themeColor: PropTypes.string,
	isVetted: PropTypes.bool,
	showAds: PropTypes.bool,
	addProgressiveAd: PropTypes.func,
	CardArticleComponent: PropTypes.elementType.isRequired,
	CardStreamComponent: PropTypes.elementType.isRequired,
	FourCardArticleComponent: PropTypes.elementType.isRequired,
	AdContainerComponent: PropTypes.elementType.isRequired,
};

export default DesktopXLRecirc;
