/* eslint-disable react/prop-types */
import React from 'react';
import cx from 'classnames';
import { fireGAEvent } from '../../shared/trackingUtils';

const triggerGAEvent = (label, value) => {
	fireGAEvent({
		eventCategory: 'Template Area Interaction',
		eventAction: 'click',
		eventLabel: label,
		eventValue: value,
	});
};

/**
 * Helper function to render cardArticle component
 * @param {Object} CardArticleComponent - The cardArticle component data
 * @returns {Function} The cardArticle
 */
const cardArticleService = ({
	CardArticleComponent = '',
	themeColor = '',
	length = 0,
	className = '',
	isFromGrid = '',
	forceSquareAspectRatio = '',
}) => ({
	index: datIndex,
	image,
	uri,
	alt,
	size,
	title,
	isPremium,
	authors,
}, itemIndex) => {
	const imageRatio = (isFromGrid && forceSquareAspectRatio) ? '1x1' : '16x9';
	const newImg = isFromGrid ? image.replace('width=500', 'width=320') : image;

	return (
		<CardArticleComponent
			themeColor={themeColor}
			inViewTrackingProps={datIndex === 0 ? {
				category: 'Template Area Interaction',
				action: 'in-view',
				label: 'xl-recirc',
			} : undefined}
			inView={false}
			image={newImg}
			href={uri}
			alt={alt}
			size={size}
			title={title}
			key={datIndex}
			isPremium={isPremium}
			className={cx('card-article', { last: itemIndex === length - 1 }, className)}
			classNameByLines="custom-stream"
			onClick={() => triggerGAEvent(`More From Forbes - XL Recirc - Story ${datIndex + 1}`, datIndex + 1)}
			isLazyLoading
			imageRatio={imageRatio}
			authors={[
				{
					link: authors[0]?.link,
					name: authors[0]?.name,
					type: authors[0]?.type,
				},
			]}

		/>
	);
};

export default cardArticleService;
