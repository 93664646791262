import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import cardArticleService from '../XLRecirc/cardArticleService';
import useWindowWidth from '../../../../next/hooks/useWindowWidth';

const CardGridArticle = ({
	isReversed,
	showAds,
	addProgressiveAd,
	data,
	isSmallDevice = false,
	themeColor,
	CardArticleComponent,
	AdContainerComponent,
	index,
}) => {
	const dataLength = (showAds && !isSmallDevice) || isSmallDevice ? 4 : 6; // 4 articles for STAR/premium, 6 for vetted
	const articles = data.slice(0, dataLength);
	const checkWindow = useWindowWidth();
	const isLargeDevice = checkWindow > 768;
	const isMobileDevice = checkWindow <= 480;
	const forceSquareAspectRatio = isMobileDevice || (isLargeDevice && checkWindow <= 1024);

	return (
		<div className={cx('card-grid-article-wrapper', { 'is-reversed': (!!isReversed && isLargeDevice) })}>
			<div className={cx('card-grid-article', { 'no-ads': showAds === false })}>
				{articles.map(cardArticleService({
					CardArticleComponent, themeColor, length: articles.length, isFromGrid: true, forceSquareAspectRatio,
				}))}
			</div>
			{showAds && !isSmallDevice
				&& (
					<div className="recirc-rail">
						<AdContainerComponent type="rightRail" showLabel>
							<fbs-ad position="stream" custom-targeting={`recx:stream${index},mffxlrecirc:true`} ref={addProgressiveAd} progressive="true" ad-id={`stream-${index}`} />
						</AdContainerComponent>
					</div>
				)}
		</div>
	);
};

CardGridArticle.propTypes = {
	isReversed: PropTypes.bool,
	showAds: PropTypes.bool,
	addProgressiveAd: PropTypes.func,
	data: PropTypes.array.isRequired,
	isSmallDevice: PropTypes.bool,
	themeColor: PropTypes.string,
	index: PropTypes.number,
	CardArticleComponent: PropTypes.elementType.isRequired,
	AdContainerComponent: PropTypes.elementType,
};

export default CardGridArticle;
