import React from 'react';
import ReactDOM from 'react-dom';
import { CardArticle, CardStream, AdContainer } from '@forbes/fbs-components';
import XLRecirc from './XLRecirc';

const renderXLRecirc = (shouldForceRender) => {
	const container = document.querySelector('.xl-recirc-ad');
	if (!container) return;

	const render = () => {
		const isVetted = window.forbes['simple-site']?.isForbesFinds;
		const isTopline = window.forbes['simple-site']?.tracking?.templateType === 'topline';
		const vettedRecircData = isVetted ? window.forbes['simple-site']?.vettedDataForXlRecirc : undefined;

		ReactDOM.render(
			<XLRecirc
				shouldForceRender={shouldForceRender}
				className="xl-recirc-standard"
				isVetted={isVetted}
				isTopline={isTopline}
				showAds={!isVetted}
				vettedRecircData={vettedRecircData}
				CardArticleComponent={CardArticle}
				CardStreamComponent={CardStream}
				AdContainerComponent={AdContainer}
			/>,
			container,
		);
	};

	const setupObserver = () => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						render();
						observer.unobserve(container);
					}
				});
			},
			{
				rootMargin: '0px 0px 400% 0px', // 4 viewports away
			},
		);
		observer.observe(container);
	};

	if (window.zephrInitialPaywallVerified) {
		setupObserver();
	} else {
		window.addEventListener('zephrInitialPaywallVerified', setupObserver, { once: true });
	}
};

export default renderXLRecirc;
