/**
 * Create grouped article data for alternating components
 * @param {Array} articles. Array of articles to be grouped.
 * @param {Array} chunks. Array of chunk sizes to group the articles.
 * @param {Array} componentTypes. Array of component types to group the articles.
 * @returns {Array} The grouped article data.
 */
const createGroupedArticleData = (articles, chunks, componentTypes) => {
	const data = [];
	let trueIndex = 0;

	while (trueIndex < articles.length) {
		for (let i = 0; i < chunks.length && i < componentTypes.length; i++) {
			const chunkSize = chunks[i];

			if (trueIndex + chunkSize <= articles.length) {
				data.push({
					type: componentTypes[i],
					data: articles.slice(trueIndex, trueIndex + chunkSize),
				});
				trueIndex += chunkSize;
			} else {
				break;
			}
		}
	}

	return data;
};

export default createGroupedArticleData;
